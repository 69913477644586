// Import Components
import R from "react-roubles"
import { Row, Col } from "antd"

// Import Store
import PaymentsStore from "../../modules/Paymonts/PaymontsStore"
import { useEffect } from "react"
import CoursesStore from "../../modules/Courses/CoursesStore"
import { observer } from "mobx-react-lite"

// Import Style

const WebinarPrice = ({ comment, course, webinar, system, user }) => {
	useEffect(() => {
		setTimeout(() => {
			if (typeof webinar?.webinar?.course !== "undefined") {
				CoursesStore.getCourse(webinar?.webinar?.course)
			}
		}, 2000)
	}, [webinar, webinar?.webinar?.course])
	return (
		<>
			<section className="webinar__prices">
				<div className="container">
					<Row gutter={[24, 24]} justify="center">
						{course?.course?.prices
							? course?.course?.prices.map((price, index) => {
									return (
										<Col
											xs={24}
											md={12}
											lg={9}
											xl={7}
											key={price.position}
											className="webinar__prices-item"
										>
											<div
												className="webinar__prices-container"
												onClick={(e) => {
													PaymentsStore.openModalConfirm(
														e,
														comment,
														webinar?.webinar
															?.course,
														system,
														price?.discount
															? price?.discount
															: price?.amount,
														user.user.id,
													)
												}}
											>
												<div>
													<div className="webinar__prices--title">
														{price.name}
													</div>
													{price.discount ? (
														<>
															<div className="webinar__prices--amount not-discount">
																<R>
																	{
																		price.amount
																	}
																</R>
															</div>
															<div className="webinar__prices--amount discount">
																<R>
																	{
																		price.discount
																	}
																</R>
															</div>
														</>
													) : (
														<>
															<div className="webinar__prices--amount">
																<R>
																	{
																		price.amount
																	}
																</R>
															</div>
														</>
													)}
												</div>
												<div
													className="webinar__prices--description"
													dangerouslySetInnerHTML={{
														__html: price.description,
													}}
												/>
												<button className="webinar__prices--button">
													Принять участие
												</button>
											</div>
										</Col>
									)
							  })
							: null}
					</Row>
				</div>
			</section>
		</>
	)
}

export default observer(WebinarPrice)
