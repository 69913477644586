import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import AuthPage from "../pages/AuthPage/AuthPage"
import PageNotFound from "../pages/NotFound/NotFound"
import SuccessPage from "../pages/SuccessPage/SuccessPage"
import FailPage from "../pages/FailPage/FailPage"
import WebinarReg from "../pages/WebinarReg/WebinarRegPage"
import RegistrationCoursePage from "../pages/RegistrationCoursePage/RegistrationCoursePage"
import RegCoursePage from "../pages/RegCoursePage/RegCoursePage"
import { Demo } from "pages/Demo/Demo"

const RoutesPublic = () => {
	return (
		<>
			<Routes>
				<Route path="/auth" element={<AuthPage />} />
				<Route path="/" element={<Navigate to="/auth" replace />} />
				<Route path="/success" element={<SuccessPage />} />
				<Route path="/fail" element={<FailPage />} />
				<Route path="/404" element={<PageNotFound />} />
				<Route path="/demo" element={<Demo />} />
				<Route path="*" element={<Navigate to="/auth" replace />} />
				<Route path="/webinarreg/:id" element={<WebinarReg />} />
				{/* <Route path="/reg/:id/:amount/:system" element={<RegistrationCoursePage />} /> */}
				<Route path="/reg/:id/:amount/:system" element={<RegCoursePage />} />
				{/* <Route path="/reg-course/:id/:amount/:system" element={<RegCoursePage />} /> */}
			</Routes>
		</>
	)
}

export default RoutesPublic
