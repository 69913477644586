import React from "react"
import { NavLink, useNavigate } from "react-router-dom"
import logo from "../../assets/images/logo.png"
import { Layout } from "antd"
import AuthStore from "../../modules/auth/AuthStore"
import "./Header.scss"
import { useState } from "react"

const Header = Layout
const HeaderComponent = () => {
	const navigate = useNavigate()

	const [burgerToggle, setBurgerToggle] = useState()

	const burgerHandler = (event) => {
		event.preventDefault()

		setBurgerToggle(!burgerToggle)
	}

	return (
		<>
			<div className={burgerToggle === true ? "burgerMenu open" : "burgerMenu"}>
				<button
					onClick={(e) => burgerHandler(e)}
					className={burgerToggle === true ? "collapse-btn open" : "collapse-btn"}
				>
					<span></span>
					<span></span>
					<span></span>
				</button>
				<div className="burgerMenu-items" onClick={burgerHandler}>
					<NavLink to="/">Мастер-классы</NavLink>
					<NavLink to="/payments">Ваши покупки</NavLink>
					<NavLink to="/personal">Личный кабинет</NavLink>

					<NavLink to="/" onClick={(e) => AuthStore.LogOut(e)} className="logout">
						Выйти
					</NavLink>
				</div>
			</div>
			<Layout className="layout header-layout">
				<Header className="container-large">
					<button className="logo" onClick={() => navigate("/")}>
						<img src={logo} alt="Modnaya Kontora" />
					</button>

					<button
						onClick={(e) => burgerHandler(e)}
						className={burgerToggle === true ? "collapse-btn open" : "collapse-btn"}
					>
						<span></span>
						<span></span>
						<span></span>
					</button>
				</Header>
			</Layout>
		</>
	)
}

export default HeaderComponent
