import { observer } from "mobx-react-lite"
import SEO from "@americanexpress/react-seo"
import { useParams } from "react-router-dom"
import HeaderBack from "components/HeaderBack"
import "./RegistrationCoursePage.scss"
import { useEffect } from "react"
import locals from "../../store/locals"
import { Course } from "./components"
import RegistrationStore from "../RegistrationPage/RegistrationStore"

const RegistrationCoursePage = () => {
	const params = useParams()

	useEffect(() => {
		RegistrationStore.checkPage(params, locals.get("user"))
	}, [params])
	return (
		<>
			<SEO title="Страница регистрации" />
			<div className="RegistrationCoursePage">
				{RegistrationStore.regTitleStatus && <HeaderBack title="Назад" store={RegistrationStore} />}
				{RegistrationStore.invalidCourseID === false ? (
					<Course params={params} />
				) : (
					<strong>Курс не найден!</strong>
				)}
			</div>
		</>
	)
}

export default observer(RegistrationCoursePage)
