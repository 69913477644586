import React from "react"
import HeaderComponent from "../../components/Header/Header"
import "./NotFound.scss"
import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import SEO from "@americanexpress/react-seo"

const PageNotFound = () => {
	const navigate = useNavigate()
	return (
		<>
			<SEO title="Страница не существует - 404" />
			<HeaderComponent />
			<div className="container">
				<div className="not-found">
					<h1 className="not-found--title">
						404<small>Страница не найдена.</small>
					</h1>
					<div className="not-found__content">
						<p>
							Мы сожалеем, но страница на которую Вы пытались
							перейти не существует.
						</p>
						<p>
							Пожалуйста вернитесь на главную страницу или
							воспользуйтесь меню сайта.
						</p>
					</div>
					<Button
						className="not-found--btn"
						type="primary"
						size={"large"}
						onClick={() => navigate("/")}
					>
						Перейти на главную
					</Button>
				</div>
			</div>
		</>
	)
}

export default PageNotFound
