import { Button, Col } from "antd"
import { observer } from "mobx-react-lite"
import LessonsStore from "modules/Courses/LessonsStore" // todo
import { useEffect } from "react"
import { Link } from "react-router-dom"
import dateFormat from "shared/hooks/dateFormat"
import config from "../../config.json"
import "./PaymentsItem.scss"

const PaymentsItem = ({ course }) => {
	const { next_lesson, last_lesson, status } = course
	const dateLesson = next_lesson ?? last_lesson
	if (!course && Number(dateLesson) === 0) return <></> // todo
	let today = new Date()
	const courseTypeIsGuide = course.course_type === "Гайд"
	// if (course?.id == 106) console.mb(course)()

	return (
		<Col
			xs={24}
			md={12}
			lg={8}
			// key={lesson?.id_course ? course?.key + lesson?.id_course : course?.key} // todo
			id={course?.id}
		>
			<div className="course-photo">
				{!course?.photo ? (
					<div className="course-img-void" />
				) : Number(dateLesson) > 0 ? (
					// todo
					<Link to={`/courses/${course?.id}`} preventScrollReset={true}>
						<img src={`${config?.apiUrl}${course?.photo}`} alt={course?.title} />
					</Link>
				) : (
					<img src={`${config?.apiUrl}${course?.photo}`} alt={course?.title} />
				)}
			</div>
			<div className="course-start">Старт {dateFormat(course?.date_start, "DD MMMM YYYY")}</div>
			<div className="course-in-numbers">
				{course?.in_numbers
					? course.in_numbers.map((number, index) => {
							let separator = ","
							if (index + 1 === course?.in_numbers.length) separator = ""
							return (
								<div key={index} className="course-number">
									{number?.num} {number?.text}
									{separator}
								</div>
							)
					  })
					: ""}
			</div>
			<div className="course-heading">{course?.title}</div>
			<div className="course-city">{course?.city}</div>
			{Number(dateLesson) > 0 && (
				<>
					{!courseTypeIsGuide && (
						<div className="course-description">
							{Number(today) / 1000 > Number(dateLesson) // todo
								? "Последняя лекция прошла "
								: "Следующая лекция начнется "}
							{dateFormat(Number(dateLesson), "DD MMMM YYYY")} {/*// todo */}
						</div>
					)}
				</>
			)}
			{(Number(dateLesson) > 0 || course.guide_file) && (
				<Link to={`/courses/${course?.id}`}>
					<Button type="primary" className="course-site-link">
						Перейти к просмотру
					</Button>
				</Link>
			)}

			{course?.site_course_url && (
				<a href={course?.site_course_url} target="_blank" rel="noreferrer" className="course-site-link">
					Полное описание курса
				</a>
			)}
		</Col>
	)
}

export default observer(PaymentsItem)
