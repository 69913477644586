import { Row } from "antd"
import { observer } from "mobx-react-lite"
import WebinarItem from "../WebinarItem"

const WebinarList = ({ webinars }) => {
	return (
		<>
			<Row gutter={[45, 45]}>
				{webinars.map((webinar, index) => {
					return <WebinarItem key={index} webinar={webinar} />
				})}
			</Row>
		</>
	)
}

export default observer(WebinarList)
