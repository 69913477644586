// Import Store
import { message } from "antd"
import { action, makeAutoObservable, runInAction } from "mobx"

import locals from "store/locals"
// API
import axios from "../../axios"
import config from "../../config.json"

class RegCoursePage {
	// Begin - State
	user = {
		name: "",
		surname: "",
		email: "",
		phone: "",
		instagram: "",
		vk: "",
		installments: "",
		promocode: "",
		checked: false,
		form: true,
		discountedPrice: null,
	}
	status = false
	course = null
	payments = []
	// End - State

	constructor() {
		makeAutoObservable(this)
	}

	// Begin - Actions
	onChange = (name, value) => {
		this.user[name] = value
		this.checkedForm()
	}

	onChangeSelect = (value) => {
		this.user.installments = value
	}

	toggleChecked = () => {
		this.user.checked = !this.user.checked
		this.checkedForm()
	}

	checkedForm = () => {
		if (this.user.name === "") {
			return (this.user.form = true)
		}

		if (this.user.surname === "") {
			return (this.user.form = true)
		}

		if (this.user.email === "") {
			return (this.user.form = true)
		}

		if (this.user.phone === "") {
			return (this.user.form = true)
		}

		if (this.user.instagram === "") {
			return (this.user.form = true)
		}

		if (this.user.vk === "") {
			return (this.user.form = true)
		}

		if (!this.user.checked) {
			return (this.user.form = true)
		}

		this.user.form = false
	}

	clickPayment = async (id, amount, system) => {
		if (Number(id) === 74) {
			this.paymentNull(Number(74), amount, system)
			this.paymentNull(Number(73), 0, system)
			this.paymentNull(Number(72), 0, system)
			this.paymentNull(Number(71), 0, system)
			this.paymentNull(Number(70), 0, system)
		} else {
			await this.payment(id, amount, system)
		}
	}
	// End - Actions

	// Begin - API
	sendUser = async () => {
		let user = new FormData()

		user.append("name", this.user.name)
		user.append("surname", this.user.surname)
		user.append("email", this.user.email)
		user.append("phone", this.user.phone)
		user.append("instagram", this.user.instagram)
		user.append("vk", this.user.vk)
		try {
			const response = await axios.post("/user/firstOrNew", user)
			runInAction(() => {
				const { token } = response.data
				locals.set("user", { token: token })
			})
		} catch (error) {
			throw new Error(`Ошибка ${error.status}`)
		}
		// await axios
		// 	.post("/user/firstOrNew", user)
		// 	.then(
		// 		action((response) => {
		// 			const { token } = response.data
		// 			locals.set("user", { token: token })
		// 		}),
		// 	)
		// 	.catch((error) => {
		// 		// https://doka.guide/js/errors/
		// 		throw new Error(`Ошибка ${error.status}`)
		// 	})
	}

	validateCourseId = async (id) => {
		// TODO: Сделать другой запрос, чтобы тот отдавал {"success": true} и {"success":false, "message": "Курс не существует"}
		try {
			const response = await axios.get("courses/course/" + id)
			runInAction(() => {
				this.status = true
				this.course = response.data
			})
		} catch (error) {
			console.error(error)
		}
		// await axios
		// 	.get("courses/course/" + id)
		// 	.then(
		// 		action((success) => {
		// 			this.status = true
		// 			this.course = success.data
		// 		}),
		// 	)
		// 	.catch((error) => {
		// 		console.error(error)
		// 	})
	}

	checkPromocode = (event, courseID, price) => {
		event.preventDefault()

		if (this.user.promocode === "") {
			return
		}

		axios
			.get("promocodes/check", { params: { promocode: this.user.promocode, price: price, course: courseID } })
			.then((success) => {
				this.user.discountedPrice = success.data.data.discountedPrice

				return message.success("Успешно применен!")
			})
			.catch((error) => {
				if (error.response?.data.code === 400) {
					this.user.promocode = ""
					return message.error("Неверный промокод!")
				}
			})
	}

	paymentNull = async (courseID, amount, systemID) => {
		let pay = new FormData()
		const title = "Обучение " + this?.course?.title

		if (!locals.get("user")) {
			return message.error("Не удалось авторизоваться!")
		}

		pay.append("User-Token", locals.get("user").token)

		pay.append("courseID", Number(courseID))

		pay.append("amount", Number(amount))

		pay.append("system", systemID)

		pay.append("comment", title)

		if (Number(systemID) === 5) {
			if (this.user.installments !== "") {
				pay.append("installment", this.user.installments)
			} else {
				return message.error("Не выбран период рассрочки!")
			}
		}

		if (this.user.promocode !== "") {
			pay.append("promocode", this.user.promocode)
		}

		await axios
			.post("payments/new", pay, {
				headers: {
					"User-Token": locals.get("user").token,
					Service: config.Service,
				},
			})
			.then(
				action((response) => {
					const { ext_id, url } = response.data
					if (Number(amount) === 0 && ext_id) {
						this.payments.push(...{ ext: ext_id })
					}

					if (
						Number(systemID) === 1 ||
						Number(systemID) === 2 ||
						Number(systemID) === 3 ||
						Number(systemID) === 5
					) {
						return (window.location.href = url)
					}

					locals.set("payments", this.payments)
				}),
			)
			.catch((error) => {
				console.debug(error)
			})
	}

	payment = async (courseID, amount, systemID) => {
		let pay = new FormData()
		const title = "Обучение " + this?.course?.title

		if (!locals.get("user")) {
			return message.error("Не удалось авторизоваться!")
		}

		pay.append("User-Token", locals.get("user").token)

		pay.append("courseID", Number(courseID))

		pay.append("amount", Number(amount))

		pay.append("system", systemID)

		pay.append("comment", title)

		if (Number(systemID) === 5) {
			if (this.user.installments !== "") {
				pay.append("installment", this.user.installments)
			} else {
				return message.error("Не выбран период рассрочки!")
			}
		}

		if (this.user.promocode !== "") {
			pay.append("promocode", this.user.promocode)
		}

		try {
			const response = await axios.post("payments/new", pay, {
				headers: {
					"User-Token": locals.get("user").token,
					Service: config.Service,
				},
			})
			const { ext_id, url, isFree } = response.data
			if ((Number(amount) === 0 && ext_id) || isFree) {
				return (window.location.href = `/success?orderId=${ext_id}`)
			}

			if (Number(systemID) === 1 || Number(systemID) === 2 || Number(systemID) === 3 || Number(systemID) === 5) {
				return (window.location.href = url)
			}
		} catch (error) {
			if (error.response.data?.success === false) {
				this.user.promocode = ""
				return message.error("Не верный промокод")
			}
		}

		// await axios
		// 	.post("payments/new", pay, {
		// 		headers: {
		// 			"User-Token": locals.get("user").token,
		// 			Service: config.Service,
		// 		},
		// 	})
		// 	.then(
		// 		action((response) => {
		// 			const { ext_id, url, isFree } = response.data
		// 			// console.debug(amount)

		// 			if ((Number(amount) === 0 && ext_id) || isFree) {
		// 				return (window.location.href = `/success?orderId=${ext_id}`)
		// 			}

		// 			if (
		// 				Number(systemID) === 1 ||
		// 				Number(systemID) === 2 ||
		// 				Number(systemID) === 3 ||
		// 				Number(systemID) === 5
		// 			) {
		// 				return (window.location.href = url)
		// 			}
		// 		}),
		// 	)
		// 	.catch((error) => {
		// 		if (error.response.data?.success === false) {
		// 			this.user.promocode = ""
		// 			return message.error("Не верный промокод")
		// 		}
		// 	})
	}
	// End - API
}

export const store = new RegCoursePage()
