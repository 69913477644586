import { observer } from "mobx-react-lite"
import PaymentsItem from "./PaymentsItem"
import { Row } from "antd"

const PaymentsList = ({ payments, courses }) => {
	// const values = new Set()
	// const filtredArray = payments.filter((item) => {
	// 	if (Number(item?.date_payed) > 0) {
	// 		const value = item["course_id"]

	// 		if (!values.has(value)) {
	// 			values.add(value)
	// 			return true
	// 		}
	// 		return false
	// 	}
	// 	return false
	// })

	if (
		// !payments &&
		!courses
	)
		return <></>

	return (
		<Row gutter={[45, 45]}>
			{/* {filtredArray.map((payment, index) => {
				const item = courses.find((course) => {
					return payment?.course_id === course?.id
				})
				return (
					typeof item !== "undefined" && payment.isActive === 1 &&
					Number(payment?.date_payed) > 0 && (
						<PaymentsItem key={index} course={item} />
					)
				)
			})} */}
			{courses.map((course) => (
				<PaymentsItem key={course.id} course={course} />
			))}
		</Row>
	)
}

export default observer(PaymentsList)
