// Import Lib
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import moment from "moment"
import SEO from "@americanexpress/react-seo"
import { observer } from "mobx-react-lite"
import { Typography, Anchor, Row, Col, Button } from "antd"

// Import Components
import config from "../../../config.json"
import Header from "../../../components/Header/Header"

// Import Style
import "../CoursesDetailPage.scss"

// Import Store
import CoursesStore from "../../../modules/Courses/CoursesStore"
import TeamStore from "../../../modules/Courses/TeamStore"
import LessonsStore from "../../../modules/Courses/LessonsStore"
import UserStore from "../../../modules/Personal/UserStore"

const { Title } = Typography
const OldPage = () => {
	const params = useParams()
	useEffect(() => {
		TeamStore.getAllTeam()
		CoursesStore.getCourse(params.id)
		UserStore.getUser(params.id)
		LessonsStore.getLessons(params.id)
	}, [params.id])

	const course = CoursesStore.course
	const lessons = LessonsStore.listLessons[params.id] ?? []
	const team = TeamStore.listTeam
	const user = UserStore.user

	if (!course) return <></>

	const timer = () => {
		if (LessonsStore?.next_lesson[0]?.date_lesson > 0) {
			const currentDate = new Date().getTime()
			const dateStart = Number(LessonsStore?.next_lesson[0].date_lesson) * 1000
			const newDate = moment(dateStart).from(currentDate).split(" ", 3)

			if (dateStart < currentDate) {
				return <span>Ещё нет доступных лекций</span>
			} else {
				return (
					<>
						<span>Следующая лекция начнется </span>
						<span className="CreateCourse__timer-item--one">{newDate[0]}</span>
						<span className="CreateCourse__timer-item--two">{newDate[2] ? newDate[1] : 1}</span>
						<span className="CreateCourse__timer-item--three">{newDate[2] ?? newDate[1]}</span>
					</>
				)
			}
		} else {
			return <span>Ещё нет доступных лекций</span>
		}
	}

	const checkTeam = LessonsStore.next_lesson.find((item) => {
		return item.id_course === params.id
	})

	let targetOffset = 50
	if (window.innerWidth < 768) targetOffset = 300

	return (
		<>
			<SEO title={course.title} />
			<Header />
			<div className="CreateCourse" id={course.id}>
				{/* Заголовок */}
				<section className="CreateCourse__title">
					<div className="container">
						<Title>{course.title}</Title>
					</div>
				</section>

				<Row>
					{lessons.length > 0 && (
						<Col xs={24} lg={8} xl={6} className="CreateCourse__body-left">
							<Anchor
								targetOffset={targetOffset}
								className={`CreateCourse__body-anchor
									${Boolean(checkTeam) === true ? "CreateCourse__body-anchor-true" : "CreateCourse__body-anchor-false"}
								`}
								offsetTop={0}
								onClick={(e) =>
									document
										.getElementsByClassName("CreateCourse__body-anchor")[0]
										.classList.remove("active")
								}
							>
								<div
									className="ant-anchor-link bold"
									onClick={(e) =>
										document
											.getElementsByClassName("CreateCourse__body-anchor")[0]
											.classList.toggle("active")
									}
								>
									Список лекций
								</div>
								{lessons.map((lesson, index) => {
									return (
										<Anchor.Link
											key={index}
											id={`lesson_item-${lesson.id}`}
											href={`#lesson_${lesson.id}`}
											title={lesson.title}
										/>
									)
								})}
							</Anchor>
						</Col>
					)}

					{team.length !== 0 && lessons.length !== 0 && (
						<Col xs={24} lg={lessons.length ? 16 : 24} xl={lessons.length ? 18 : 24}>
							{team.length > 0 && (
								<section className="CreateCourse__team">
									<div className="container">
										{team.map((item, index) => {
											return (
												Number(item.id) === Number(user.curator) && (
													<Row
														gutter={[60, 60]}
														align="middle"
														id={`created_by-${item.id}`}
														key={index}
														className="CreateCourse__team-body"
													>
														<Col xs={24} sm={9} lg={6}>
															<div className="CreateCourse__team-img">
																<img
																	src={`${config.apiUrl}${item.photo}`}
																	alt={item.title}
																/>
															</div>
														</Col>
														<Col
															xs={24}
															sm={15}
															// md={15}
															lg={12}
															className="CreateCourse__team-body"
														>
															<strong className="CreateCourse__team-title">
																{item.name}
															</strong>
															<p className="CreateCourse__team-post">{item.post}</p>
															<div className="CreateCourse__team-hr"></div>
															<div
																className="CreateCourse__team-description"
																dangerouslySetInnerHTML={{
																	__html: item.description,
																}}
															></div>

															{Boolean(checkTeam) && item?.contact && (
																<div className="CreateCourse__lessons-item--button">
																	<Button
																		size="large"
																		type="primary"
																		href={`${item?.contact}`}
																	>
																		Связаться с куратором
																	</Button>
																</div>
															)}
														</Col>
													</Row>
												)
											)
										})}
									</div>
								</section>
							)}

							{lessons.length > 0 && (
								<section className="CreateCourse__body">
									<div className="CreateCourse__lessons">
										<div className="container">
											{lessons.map((lesson, index) => {
												return (
													<div
														key={index}
														id={`lesson_${lesson.id}`}
														className="CreateCourse__lessons-item"
													>
														<Title
															level={2}
															id="lesson"
															className="CreateCourse__lessons-item--title"
														>
															{lesson.title}
														</Title>

														<div className="CreateCourse__lessons-item-iframe">
															<div
																dangerouslySetInnerHTML={{
																	__html: `<iframe
																			title="${lesson.title}"
																			src="https://player.vimeo.com/video/${lesson.video}"
																			frameBorder="0"
																			allow="autoplay; fullscreen"

																		/>`,
																}}
															/>
														</div>

														{team.map((item) => {
															return (
																Number(item.id) === Number(user.curator) && (
																	<div key={index}>
																		{item?.contact && (
																			<div className="CreateCourse__lessons-item--button">
																				<Button
																					size="large"
																					type="primary"
																					href={`${item?.contact}`}
																				>
																					Связаться с куратором
																				</Button>
																			</div>
																		)}
																	</div>
																)
															)
														})}
													</div>
												)
											})}
										</div>
									</div>
								</section>
							)}
						</Col>
					)}

					<Col xs={24}>
						<section className="CreateCourse__timer">{timer()}</section>
					</Col>
				</Row>
			</div>
		</>
	)
}

export default observer(OldPage)
