import React from "react"
import Header from "../../components/Header/Header"
import { Typography } from "antd"
import SEO from "@americanexpress/react-seo"

const { Title } = Typography
const CoursesPage = () => {
	return (
		<>
			<SEO title="Список курсов" />
			<Header />
			<div className="container">
				<Title>Список курсов</Title>
			</div>
		</>
	)
}

export default CoursesPage
