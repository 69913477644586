import { observer } from "mobx-react-lite"
import LessonsStore from "../../modules/Courses/LessonsStore";
import {ReactComponent as ReplyIcon} from "../../assets/images/reply.svg";
import {Button, Input} from "antd";

const { TextArea } = Input

const CommentsList = ({lesson, canreply, team, short = false}) => {
    let item = LessonsStore.listComments.find(item => item.lesson_id === lesson.id)

    return (
        <>
            {lesson?.comments === 1 &&
                <div
                    className="CreateCourse__lessons-item-comments"
                >
                    {item &&
                        item?.comments.map((comment, i) => {
                            if(i >= 1 && short === true) return
                            let name = comment.created.name
                            let surname = comment.created?.surname
                            let commentatorClass = "single-comment__name"
                            let userFromTeam = false
                            if(team){
                                userFromTeam = team.includes(comment.created.id)
                                commentatorClass = (userFromTeam === true) ? "single-comment__name colored" : "single-comment__name"
                            }
                            return (
                                <div key={i} className="single-comment">
                                    <div className="single-comment__header">
                                        <div
                                            className={commentatorClass}
                                        >
                                            {userFromTeam === true ? (
                                                <>{name} {surname}</>
                                            ) : (
                                                <>{name} {surname[0]}.</>
                                            )}
                                        </div>
                                    </div>
                                    <div className="single-comment__text">
                                        {comment.text}
                                        {canreply === true &&
                                            <div
                                                className="single-comment__reply"
                                                onClick={_ => LessonsStore.reply_to = comment.id}
                                            >
                                                <ReplyIcon/>ответить (#{comment.id})
                                            </div>
                                        }
                                    </div>
                                    {comment?.replies && comment?.replies.length > 0 &&
                                        <div className="single-comment__replies">
                                            {comment.replies.map((subcomment, k) => {
                                                let name2 = subcomment.created.name
                                                let surname2 = subcomment.created?.surname
                                                return (
                                                    <div key={k} className="single-comment">
                                                        <div className="single-comment__header">
                                                            <div className="single-comment__replyto">{name} {surname[0]}.<ReplyIcon /></div>
                                                            <div className="single-comment__name">{name2} {surname2}</div>
                                                        </div>
                                                        <div className="single-comment__text">{subcomment.text}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                    {short === true &&
                        <div
                            className="CreateCourse__lessons-item-comments__footer"
                            onClick={_ => LessonsStore.toggleCommentsModal(lesson.id)}
                        >
                            <div className="comments-count">Комментарии ({item?.comments.length ?? 0})</div>
                            <div className="add-comments">+</div>
                        </div>
                    }
                    {short === false &&
                        <div className="add-comment">
                            {LessonsStore.reply_to > 0 &&
                                <div className="reply-to">
                                    Вы отвечаете на комментарий #{LessonsStore.reply_to} <span className="cancel-reply" onClick={_ => LessonsStore.reply_to = 0}>(отменить)</span>
                                </div>
                            }
                            <TextArea
                                className="comment-form"
                                value={LessonsStore.comments.find(item => item.lesson_id === lesson.id)?.text}
                                onChange={e => LessonsStore.onChangeComment(lesson.id, e.target.value)}
                                placeholder="Введите ваш комметарий"
                            />
                            <Button
                                className="send-button"
                                onClick={_ => LessonsStore.sendComment(lesson.id, LessonsStore.reply_to)}
                            >
                                Опубликовать
                            </Button>
                        </div>
                    }

                </div>
            }
        </>
    )
}

export default observer(CommentsList)