import classNames from "classnames"
import { useCallback, useRef, useState } from "react"

import styles from "./input.module.scss"

export const Input = ({ placeholder, type, value, onChange, onPaste, onKeyDown, required }) => {
	placeholder = placeholder || ""
	type = type || "text"
	required = required || false

	const [focus, setFocus] = useState(false)

	const ref = useRef()

	const forceFocus = useCallback(() => {
		ref?.current?.focus()
		setFocus(true)
	}, [ref])

	const onWrapperClick = useCallback(() => {
		forceFocus()
	}, [forceFocus])

	const onBlur = useCallback(() => {
		setFocus(false)
	}, [setFocus])

	const onFocus = useCallback(() => {
		setFocus(true)
	}, [setFocus])

	const inputFocus = classNames(styles.container, { [styles.containerFocus]: focus })

	return (
		<div className={inputFocus} onClick={onWrapperClick}>
			<input
				className={styles.input}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				value={value}
				placeholder={placeholder}
				type={type}
				ref={ref}
				required={required}
			/>
			<label>{placeholder}</label>
		</div>
	)
}
