import failImg from "../../assets/images/fail.png"
import "./FailPage.scss"
import SEO from "@americanexpress/react-seo"
import ym from "react-yandex-metrika";

const FailPage = () => {
	ym('reachGoal', 'failPayment')

	return (
		<>
			<SEO title="Оплата была отклонена банком" />
			<div className="FailPage">
				<div className="FailPage__body">
					<div className="FailPage__img">
						<img src={failImg} alt="" />
					</div>
					<div className="FailPage__content">
						<h1 className="FailPage__content--title">
							Оплата была отклонена банком.
						</h1>
						<p className="FailPage__content--top">
							Попробуйте снова.
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default FailPage
