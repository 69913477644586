import { observer } from "mobx-react-lite"
import { useRef } from "react"
import { useEffect } from "react"

const paddingParent = 25

const LessonText = ({ text }) => {
	let tmp = document.createElement("DIV")
	tmp.innerHTML = text
	const parent = useRef(null)
	const child = useRef(null)
	useEffect(() => {
		if (!parent.current || !child.current) return
		const childHeight = child.current.clientHeight
		const parentHeight = parent.current.clientHeight
		if (childHeight < parentHeight - paddingParent) parent.current.classList.add("opened")
	}, [parent.current, child.current])
	if (tmp.textContent <= 1) return <></>
	return (
		<div
			ref={parent}
			className="CreateCourse__lessons-item-text"
			onClick={(e) => e.currentTarget.classList.toggle("opened")}
		>
			<div
				ref={child}
				dangerouslySetInnerHTML={{
					__html: text,
				}}
			/>
		</div>
	)
}

export default observer(LessonText)
