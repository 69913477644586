import Header from "../../components/Header/Header"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import "./HomePage.scss"
import TitlePage from "../../components/TitlePage/TitlePage"

import SEO from "@americanexpress/react-seo"
import WebinarList from "../../components/WebinarList"

// Import Store
import WebinarsStore from "../../modules/Webinars/Webinar.store"

const HomePage = () => {
	useEffect(() => {
		WebinarsStore.getAllWebinars()
	}, [])

	return (
		<>
			<SEO title="Личный кабинет - Modnaya Kontora" />
			<Header />

			<div className="HomePage">
				<div className="container">
					<TitlePage title="Мастер-классы" />
					{WebinarsStore?.listWebinar?.length > 0 ? (
						<WebinarList webinars={WebinarsStore?.listWebinar} />
					) : null}
				</div>
			</div>
		</>
	)
}

export default observer(HomePage)
