import { observer } from "mobx-react-lite"
import Registration from "../../components/Registration/Registration"
import AuthForm from "../../modules/auth/AuthForm"
import {  Button, Space } from "antd"
import "./WebinarRegPage.scss"
import WebinarRegStore from "./WebinarRegStore"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import locals from "../../store/locals"
import HeaderBack from "../../components/HeaderBack"
import SEO from "../../components/SEO";

const WebinarRegPage = () => {
	const params = useParams()
	useEffect(() => {
		WebinarRegStore.getWebinar(Number(params.id))
		if (Number(params.id) > 0) {
			if (
				locals.get("user") &&
				locals.get("user").token &&
				locals.get("user").id > 0
			) {
				WebinarRegStore.checkWebinarReg(Number(params.id))
				WebinarRegStore.alreadyAuthorised = true
			} else {
				WebinarRegStore.successRegistration = false
			}
		} else {
			WebinarRegStore.invalidWebinarID = true
		}
	}, [params])

	return (
		<>
			<SEO title={"Регистрация на мастер-класс " + WebinarRegStore.webinarName} />
			<div className="RegistrationPage">
				<div className="RegistrationPage__heading">
					Регистрация на мастер-класс
					<span>{WebinarRegStore.webinarName}</span>
				</div>
				{WebinarRegStore.regTitleStatus ? (
					<HeaderBack title="Назад" store={WebinarRegStore} />
				) : null}

				{WebinarRegStore.invalidWebinarID === false ? (
					<>
						{WebinarRegStore.successRegistration === false ? (
							<>
								{WebinarRegStore.alreadyAuthorised === true ? (
									<>
										<Space
											size="middle"
											direction="vertical"
											align="center"
										>
											<Button
												type="primary"
												size="large"
												onClick={() =>
													WebinarRegStore.makeWebinar(
														Number(params.id),
													)
												}
												block
											>
												Зарегистрироваться
											</Button>
										</Space>
									</>
								) : (
									<>
										{!WebinarRegStore.regTitleStatus ? (
											<>
												<div className="Registration__heading">Для участия в мастер-классе пройдите регистрацию.</div>
												<div className="Registration__subheading">В вашем личном кабинете вам будет предоставлен доступ к участию в новых мастер-классах и к архиву проведенных.</div>
												<Space
													size="middle"
													direction="vertical"
												>
													<Button
														type="primary"
														size="large"
														onClick={() =>
															WebinarRegStore.regButton()
														}
														block
													>
														Зарегистрироваться
													</Button>
													<div className="Registration__heading--level2">Авторизуйтесь, если вы уже регистрировались.</div>
													<Button
														size="large"
														onClick={() =>
															WebinarRegStore.authButton()
														}
														block
													>
														Авторизоваться
													</Button>
												</Space>
											</>
										) : WebinarRegStore.regStatus ? (
											<Registration
												webinarID={Number(params.id)}
											/>
										) : (
											<AuthForm
												webinarID={Number(params.id)}
											/>
										)}
									</>
								)}
							</>
						) : (
							<>
								<div style={{ textAlign: "center" }}>
									<div>
										Вы успешно зарегистрировались на
										вебинар "
										<strong>
											{WebinarRegStore.webinarName}
										</strong>
										".
									</div>
									<div>
										Для просмотра
										<button
											disabled={
												WebinarRegStore.buttonDisabled
											}
											className="buttonWebinar"
											onClick={() => {
												WebinarRegStore.buttonDisabled = true
												window.open(
													"https://student.modnayakontora.ru/",
													"_blank",
												)
											}}
										>
											перейдите по ссылке
										</button>
									</div>
								</div>
							</>
						)}
					</>
				) : (
					<>Invalid data</>
				)}
			</div>
		</>
	)
}

export default observer(WebinarRegPage)
