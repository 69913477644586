import { observer } from "mobx-react-lite"
import SEOComponent from "@americanexpress/react-seo"

const SEO = ({title}) => {
    return (
        <SEOComponent
            title={title}
            locale="ru-RU"
            description=""
            siteUrl={window.location.href}
            image={{
                src: 'https://student.modnayakontora.ru/ogimage.jpg'
            }}
        />
    )
}

export default observer(SEO)