import { observer } from "mobx-react-lite"
import ImageGallery from "react-image-gallery"
import config from "../../config.json"

const Gallery = ({ items }) => {
	if (items === "") {
		console.error("Не получены изображения с сервера")
		return <div>Произошла ошибка при загрузке изображений с сервера обратитесь к администратору</div>
	}
	let images = []
	JSON.parse(items).map((item) => {
		images.push({
			original: config.apiUrl + item,
			thumbnail: config.apiUrl + item,
		})
	})

	return (
		<div className="CreateCourse__lessons-item-gallery">
			<ImageGallery
				showThumbnails={false}
				showPlayButton={false}
				showBullets={true}
				showFullscreenButton={false}
				items={images}
			/>
		</div>
	)
}

export default observer(Gallery)
