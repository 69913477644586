import { Player } from "@lottiefiles/react-lottie-player"
// import
import style from "./style.module.scss"

export const Demo = () => {
	return (
		<div className="container">
			<h1>Using Lottie with React JS ⚛️</h1>

			<Player src="/Figura.json" autoplay loop className={style.player} />
		</div>
	)
}
