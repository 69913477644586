// Import Framework

// Import Components
import { useParams } from "react-router-dom"
import NewPage from "./type/NewPage"
import OldPage from "./type/OldPage"

const CoursesDetailPage = () => {
	const params = useParams()

	return params.id !== 2 ? <NewPage /> : <OldPage />
}

export default CoursesDetailPage
