import { observer } from "mobx-react-lite"
import AuthForm from "../../modules/auth/AuthForm"
import RegistrationForm from "../../modules/auth/RegistrationForm"
import logo from "../../assets/images/logo.png"
import "./AuthPage.scss"
import SEO from "../../components/SEO"
import { Divider } from "antd"

const AuthPage = observer(() => {
	return (
		<>
			<SEO title="Страница авторизации" />
			<div className="authPage">
				<div className="authPage__img">
					<img src={logo} alt="ModnayaKontora" />
				</div>
				<AuthForm />
				{/* <div className="textDivider">или</div> */}
				<div className="authPageReg">
					<Divider />
					<RegistrationForm />
				</div>
			</div>
		</>
	)
})

export default AuthPage
