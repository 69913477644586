import { observer } from "mobx-react-lite"
import { BrowserRouter } from "react-router-dom"
import RoutesAuth from "./routes/RoutesAuth"
import RoutesPrivate from "./routes/RoutesPrivate"
import Middleware from "./Middleware"
import "./assets/styles/App.scss"
import SEO from "./components/SEO"
import { YMInitializer } from "react-yandex-metrika"

window.dataLayer = window.dataLayer || []
console.mb = (...props) => {
	const parse = props.map((item) => (item === undefined ? undefined : JSON.parse(JSON.stringify(item))))
	return console.log.bind(this, ...parse)
}

const App = observer(() => {
	return (
		<>
			<SEO title="Личный кабинет Модной Конторы" />
			<YMInitializer
				accounts={[89465591, 51020822]}
				options={{
					clickmap: true,
					trackLinks: true,
					accurateTrackBounce: true,
					webvisor: true,
					ecommerce: "dataLayer",
				}}
				version="2"
			/>
			<BrowserRouter>{Middleware.isLogged() ? <RoutesPrivate /> : <RoutesAuth />}</BrowserRouter>
		</>
	)
})

export default App
