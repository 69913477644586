import React, { useEffect } from "react"
import Header from "../../components/Header/Header"
import { Row, Col } from "antd"
import "./PersonalPage.scss"
// import PersonalNavigation from "../../components/Personal/PersonalNavigation/PersonalNavigation"
import PersonalUser from "../../components/Personal/PersonalUser/PersonalUser"
// import PersonalHistory from "../../components/Personal/PersonalHistory/PersonalHistory"
import UserStore from "../../modules/Personal/UserStore"
import { observer } from "mobx-react-lite"
import TitlePage from "../../components/TitlePage/TitlePage"

import SEO from "@americanexpress/react-seo"

const PersonalPage = () => {
	useEffect(() => {
		UserStore.getUser()
	})
	return (
		<>
			<SEO title="Личные данные" />
			<Header />
			<div className="container">
				<TitlePage title="Личные данные" />
				<Row gutter={[24, 24]} className="PersonalUser">
					{/* <Col xs={24} sm={10} md={8} lg={4}>
						<PersonalNavigation />
					</Col> */}
					<Col xs={24} sm={18} md={12} lg={9}>
						<PersonalUser />
					</Col>
					{/* <Col xs={24} sm={24} lg={12}>
						<PersonalHistory />
					</Col> */}
				</Row>
			</div>
		</>
	)
}

export default observer(PersonalPage)
