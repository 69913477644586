import { action, makeAutoObservable } from "mobx"
import axios from "../../axios"
import locals from "../../store/locals"

class PaymentsStore {
	limit = 0
	listPayments = []
	viewPayments = []
	modalConfirm = {
		status: false,
		comment: null,
		course: null,
		system: null,
		price: null,
		user: null,
	}
	constructor() {
		makeAutoObservable(this)
	}

	openModalConfirm = (event, comment, courseID, system, amount, payerID) => {
		event.preventDefault()
		this.modalConfirm = {
			status: true,
			comment: comment,
			course: courseID,
			system: system,
			price: amount,
			user: payerID,
		}
	}

	closeModalConfirm = (event) => {
		event.preventDefault()
		this.modalConfirm.status = false
	}

	getPaymentsCount = () => {
		axios
			.get("/payments/count")
			.then(
				action((success) => {
					this.limit = success.data.count
					this.getAllPayments()
				}),
			)
			.catch((error) => console.error(error))
	}

	getAllPayments() {
		if (this.limit !== 0) {
			axios
				.get("/payments/payments", { params: { limit: this.limit } })
				.then(
					action((success) => {
						const [payments, count] = success.data

						this.limit = count[0].count
						this.listPayments = payments.reverse().map((payment) => {
							return { key: payment.id, ...payment }
						})
					}),
				)
				.catch((error) => {
					console.error(error)
				})
		}
	}

	newPayment(e, comment, courseID, system, amount, payerID, promocode = null) {
		e.preventDefault()
		let payment = new FormData()

		payment.append("User-Token", locals.get("user").token)
		payment.append("courseID", Number(courseID))
		payment.append("amount", Number(amount))
		payment.append("comment", comment)
		if (promocode !== null) payment.append("promocode", promocode)
		payment.append("system", Number(system))
		payment.append("payerID", Number(payerID))

		axios.post("/payments/new", payment).then(
			action((success) => {
				if (success.data !== 0) {
					// window.open(success.data)
					window.location.href = success.data.url
				}
			}),
		)
	}
}

export default new PaymentsStore()

/*

amount: "15000" - стоимость
comment: "" - описание покупки
course_id: "32" - id курса
date_create: "1651061179" - дата создания
date_payed: "1651061286" - дата оплаты
date_update: "" - дата обновления стоимости
ext_id: "123" - операция в сбере
id: "4" - id оплаты
payer_id: "4" - пользователь
promocode: "" - наличие промокода
system: "1" - тип платежной системы
updated_by: "0" -
*/
